import { GATSBY_ENV } from "gatsby-env-variables"
import { sendToZendesk } from "../../../services/zendeskService"

import { GATSBY_FORM_FIELD_ZENDESK_ID } from "gatsby-env-variables"

import { telerivetApi } from "../../../services/telerivet"
import { handleError } from "../../../services/handleError"
import {
  doctorEnrollmentTicketTemplate,
  patientEnrollmentTicketTemplate,
} from "../utils/enrollmentTicketTemplate"
import {
  doctorMessageTemplate,
  patientMessageTemplate,
} from "../utils/enrollmentSMSTemplate"

const parseZendeskField = enrollmentData => {
  if (enrollmentData?.specialization) {
    enrollmentData.parsedSpecialization =
      enrollmentData.specialization.value !== "Other (please specify)"
        ? enrollmentData.specialization.value
        : enrollmentData.otherSpecialization
  }

  return enrollmentData
}

export const sendEnrollmentFormToZendesk = config => {
  let { enrollmentData, documents, ticketTemplate, type } = config // Removed file array
  let isTest = GATSBY_ENV !== "production"
  const { sku } = enrollmentData

  let tags = [`${type}`, "bm_patientlink", `${sku}`]
  if (isTest) tags.push("test")

  let subject = `${isTest ? "[TEST] " : ""}Enrollment Form of ${
    enrollmentData?.firstName
  } ${enrollmentData?.lastName}`

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${enrollmentData?.firstName} ${enrollmentData?.lastName}`,
      email: enrollmentData?.emailAddress,
    },
    custom_fields: [
      {
        id: GATSBY_FORM_FIELD_ZENDESK_ID,
        value: JSON.stringify({ formData: parseZendeskField(enrollmentData) }),
      },
    ],
    comment: { body: ticketTemplate({ enrollmentData }) },
  }

  return sendToZendesk(requestBody || {}, documents || [])
}

export const handleUserEnroll = async ({
  type,
  enrollmentData,
  documents,
  callback,
  errorCallback,
}) => {
  try {
    await sendEnrollmentFormToZendesk({
      enrollmentData,
      documents,
      ticketTemplate:
        type === "doctor"
          ? doctorEnrollmentTicketTemplate
          : patientEnrollmentTicketTemplate,
      type: type === "doctor" ? "patient_nomination" : "patient_enrollment",
    })

    await telerivetApi().post("/messages/send", {
      content:
        type === "doctor" ? doctorMessageTemplate() : patientMessageTemplate(),
      to_number: enrollmentData?.mobileNumber,
    })

    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}
