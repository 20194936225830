import axios from "axios"

import {
  GATSBY_TELERIVET_CLOUD_FUNCTION_URL,
  GATSBY_TELERIVET_API_KEY,
  GATSBY_TELERIVET_PROJECT_ID,
  GATSBY_TELERIVET_ROUTE_ID,
} from "gatsby-env-variables"

export const telerivetApi = () => {
  let api = axios.create({
    baseURL: GATSBY_TELERIVET_CLOUD_FUNCTION_URL,
    headers: {
      "Content-Type": "application/json",
      api_key: GATSBY_TELERIVET_API_KEY,
      project_id: GATSBY_TELERIVET_PROJECT_ID,
      route_id: GATSBY_TELERIVET_ROUTE_ID,
    },
  })

  return api
}
