import moment from "moment"

export const doctorMessageTemplate = () => {
  return `Thank you for nominating a patient to the PatientLink Program. Your nomination has been received and will be processed within one working day. If this is your first time nominating a patient to the program, please allow an additional 1-2 working days for us to confirm your eligibility with BIPHI. You will receive an SMS with the status of your nomination once it has been processed.\n\nOnce we have verified your patient is eligible to receive the samples, we will send them an SMS reminding them to provide delivery details at respimat.medgrocer.com/patient.\n\nFeel free to reach us at respimat@medgrocer.com or 0968 761 8852 for any questions.\n\n– MedGrocer`
}

export const patientMessageTemplate = () => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] Thank you for choosing to be a part of the PatientLink Sampling Program. Your enrollment has been received and will be processed within one working day. You will receive an SMS with the status of your enrollment once it has been processed. Feel free to reach us at patientlink.medgrocer.com/help-center/#contact-us for questions. 
  \n– MedGrocer`
}
