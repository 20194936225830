export const defaultValues = ["", "null null ", "Metro Manila, ", "Other", undefined]

export const parseSummaryValue = (data, type) => {
  switch (type) {
    case "text":
      return data
    case "array":
      return data.join(", ")
    case "isPrescribed":
      return data?.length > 0 ? "Yes" : "No"
    case "date":
      return `${data.month.value} ${data.date.value} ${data.year}`
    case "address":
      let addressList = ""

      addressList = `${data?.addressType ? `${data?.addressType}, ` : ""}${
        data?.streetAddress ? `${data?.streetAddress}, ` : ""
      }${data?.barangay.value ? `${data?.barangay.value}, ` : ""}${
        data?.province?.value ? `${data?.province?.value}, ` : ""
      }${data?.city?.value ? `${data?.city?.value}` : ""}`

      return addressList
    default:
      return null
  }
}
