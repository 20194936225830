import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"
import { navigate } from "gatsby"
import { get } from "lodash"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import FormCheckbox from "elements/Form/FormCheckbox"
import EditDetailsButton from "elements/EditDetailsButton"
import PrescriptionDetails from "elements/PrescriptionDetails"

import {
  GASTBY_ENCRYPTED_KEY,
  GATSBY_ENCRYPTED_VALUE,
} from "gatsby-env-variables"

import styles from "./utils/enrollment.module.scss"
import { AppContext } from "../../context/AppContext"
import { RESET_STATE } from "../../context/AppReducer"
import { handleUserEnroll } from "./services/enrollment"
import { parseSummaryValue, defaultValues } from "./services/summary"

const Summary = props => {
  const { backPath, nextPath, module, basePath, summaryConfig } = props.pageContext
  const nextLink = module?.sku ? `/${module.sku}/${module.name}/${nextPath}` : `/${module.name}/${nextPath}`
  const backLink = module?.sku ? `/${module.sku}/${module.name}/${backPath}` : `/${module.name}/${backPath}`

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { state, dispatch } = useContext(AppContext)

  let doctorEnrollmentValues = state.patientNomination
  let patientEnrollmentValues = state.patientEnrollment

  let initialValues =
    module.name === "doctor" ? doctorEnrollmentValues : patientEnrollmentValues

  initialValues = {
    ...initialValues,
    sku: module.sku,
  }

  const layoutSubtitle = (
    <span>
      Please review if the following information is correct.
      <br className="is-hidden-mobile" /> {module.subtitle}
    </span>
  )

  const handleSuccessCallback = () => {
    setLoading(false)
  }

  const handleSubmitError = ({ message }) => {
    setErrorMessage(message)
    setLoading(false)
  }

  useEffect(() => {
    if (module.name === "doctor") {
      navigate(basePath)
    }
  }, [module])

  useEffect(() => {
    if (sessionStorage.getItem(GASTBY_ENCRYPTED_KEY) !== GATSBY_ENCRYPTED_VALUE)
      navigate(backLink)
  }, [backPath, module.name])

  const handleSubmit = () => {
    setLoading(true)
    handleUserEnroll({
      type: module.name,
      enrollmentData: initialValues,
      documents: state.documents,
      callback: () => {
        handleSuccessCallback()
        dispatch({
          type: RESET_STATE,
        })
        navigate(nextLink)
      },
      errorCallback: handleSubmitError,
    })
  }

  let presriptionBlurb =
    "I certify that I prescribed Tiotropium Bromide + Olodaterol HCl (Spiolto® Respimat®) and would like my patient to receive a single Spiolto® Respimat® refill pack to initiate their treatment."

  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={layoutSubtitle}
    >
      <Container isCentered>
        {summaryConfig.map(section => {
          let hasSummaryData = false
          return (
            <Section
              title={section.section}
              isRequired
              addOns={{
                right: (
                  <EditDetailsButton
                    route={`${backLink}${section.route}`}
                  />
                ),
              }}
            >
              <div className="table-container mb-3">
                <table class="table is-fullwidth is-size-5">
                  <tbody>
                    {section.fields.map(field => {
                      if (
                        !defaultValues.includes(
                          parseSummaryValue(
                            get(initialValues, field.key),
                            field.type
                          )
                        )
                      ) {
                        hasSummaryData = true

                        if (field.key === "otherSpecialization") {
                          let specialization = parseSummaryValue(
                            get(initialValues, "specialization.value"),
                            "text"
                          )

                          if (specialization !== "Other (please specify)")
                            return null
                        }

                        if( field.key === "otherHospital" ) {
                          let hospital = parseSummaryValue(
                            get(initialValues, "hospitalClinic.value"),
                            "text"
                          )

                          if( hospital !== "Other" ) return null
                        }

                        if (field.key === "deliveryAddress.clinicHours") {
                          let clinicHours = parseSummaryValue(
                            get(initialValues, "deliveryAddress.addressType"),
                            "text"
                          )

                          if (clinicHours !== "Hospital") return null
                        }

                        return (
                          <tr>
                            <td className="has-text-weight-bold">
                              {field.label}
                            </td>
                            <td
                              className={classNames(
                                styles["summary__tableData"]
                              )}
                            >
                              {parseSummaryValue(
                                get(initialValues, field.key),
                                field.type
                              )}
                            </td>
                          </tr>
                        )
                      }
                      return null
                    })}
                    {!hasSummaryData && (
                      <Message color="warning">
                        No data found for {section.section.toLowerCase()}{" "}
                        section.
                      </Message>
                    )}
                  </tbody>
                </table>
              </div>
            </Section>
          )
        })}
        {module.name === "doctor" && (
          <Formik initialValues={initialValues}>
            {({ values }) => (
              <Form>
                <FormCheckbox
                  name="patientAlreadyPrescribed"
                  values={[presriptionBlurb]}
                  options={[presriptionBlurb]}
                />
              </Form>
            )}
          </Formik>
        )}
        {state.documents.length > 0 && (
          <Section
            title="Uploded Prescription"
            isRequired
            addOns={{
              right: (
                <EditDetailsButton
                  route={`${backLink}/#clinic-information`}
                />
              ),
            }}
          >
            <PrescriptionDetails />
          </Section>
        )}
        {errorMessage && <Message color="danger">{errorMessage}</Message>}
        <ActionButtons
          back={{
            label: "Back",
            callback: () => {
              navigate(backLink)
            },
          }}
          submit={{
            label: "Submit",
            onClick: handleSubmit,
            loading,
          }}
        />
      </Container>
    </Layout>
  )
}

export default Summary
