export const doctorEnrollmentTicketTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    prcNumber,
    specialization,
    otherSpecialization,
    patientFirstName,
    patientLastName,
    patientMobileNumber,
    patientEmailAddress,
    patientAlreadyPrescribed,
  } = enrollmentData

  let doctorSpecialization =
    specialization.value !== "Other (please specify)"
      ? specialization.value
      : otherSpecialization

  return `Doctor Information
First Name: ${firstName}
Last Name: ${lastName}
Email: ${emailAddress}
Mobile Number: ${mobileNumber}
PRC License Number: ${prcNumber}
Specialization: ${doctorSpecialization}
-----
Patient Information
First Name: ${patientFirstName}
Last Name: ${patientLastName}
Mobile Number: ${patientMobileNumber}
Email Address: ${patientEmailAddress || "N/A"}`
}

export const patientEnrollmentTicketTemplate = ({ enrollmentData }) => {
  let {
    lastName,
    firstName,
    mobileNumber,
    emailAddress,
    nominationCode,
    deliveryAddress,
    prescribingDoctor,
    hospitalClinic,
    otherHospital,
    secondaryContactLastName,
    secondaryContactFirstName,
    secondaryContactMobileNumber,
    sku,
  } = enrollmentData

  let hospital = hospitalClinic?.value === "Other" ? otherHospital : hospitalClinic?.label
  let skuName = sku === "respimat" ? "Tiotropium Bromide + Olodaterol HCl (Spiolto® Respimat®)" : "Empagliflozin (Jardiance®) 10mg"
  // let birthdayInformation = `${birthday.month.value} ${birthday.date.value} ${birthday.year}`

  return `Personal Details
First Name: ${firstName}
Last Name: ${lastName}
Email: ${emailAddress}
Mobile Number: ${mobileNumber}
Prescribing Doctor: ${prescribingDoctor}
Hospital or Clinic: ${hospital || "N/A"}
Nomination Code: ${nominationCode}
-----
Delivery Details
Address Type: ${deliveryAddress.addressType}
Street Address: ${deliveryAddress.streetAddress}
Barangay: ${deliveryAddress.barangay.value}
Province: ${deliveryAddress.province.value}
City: ${deliveryAddress.city.value}
Notes: ${deliveryAddress.notes || "N/A"}
Preferred Delivery Time: ${deliveryAddress.preferredDeliveryTime || "N/A"}
Requested Medicine: ${skuName}
----
Secondary Contact
First Name: ${secondaryContactFirstName}
Last Name: ${secondaryContactLastName}
Contact Number: ${secondaryContactMobileNumber}`
}
